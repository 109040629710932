const single_news_and_announcements_en = {
	newsAndAnnouncement: {
		share: 'Share',
    },
	relatedArticles: {
		title: 'Related Articles',
    },
}

const single_news_and_announcements_cn = {
	newsAndAnnouncement: {
		share: '分享',
	},
	relatedArticles: {
		title: '相关文章',
	},
}

export { single_news_and_announcements_en, single_news_and_announcements_cn } 