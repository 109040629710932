<template>
  <div id="app">
    <!-- contact drawer -->
      <div class="overlay-contact-form" :class="{open: showContact}" @click="closeContact"></div>
      <Contact class="contact" :class="{open: showContact}" />

    <!-- Pop-up: QR Code -->
    <div class="overlay-qr-code" @click="hideQRcode">
      <div class="align-self-center">
        <p class="title">Scan the QR code<br />to download</p>

        <div>
            <img class="img-qr-code" src="@/assets/img/qr-code.svg" />
        </div>

        <p class="description">
          <!-- <img class="logo" src="@/assets/img/apple-white.svg" /> -->
          Or tap here to download iOS version
        </p>

        <div class="close">
          <img src="@/assets/img/close-button.svg"/>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <transition class="site-transition" enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<style style lang="scss">
@import "../node_modules/animate.css/animate.css";
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900&display=swap");

#app {
  font-family: "Roboto", "Noto Sans SC", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.animated.page {
  position: absolute;
  top: 0;
  left: 15px;
  bottom: 0;
  right: 15px;
}
</style>

<script>
import Vue from 'vue';
import VueMeta from 'vue-meta';

import { EventBus } from "@/eventBus.js";
import Contact from "@/views/Contact-Us.vue";
import $ from 'jquery';

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

export default {
  metaInfo: {
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=no' }
    ],
  },
  components: {
    Contact
  },
  data: function() {
    return {
      showContact: false
    };
  },
  created() {
    EventBus.$on("open-contact", () => {
      this.showContact = true;
      window.addEventListener("scroll", this.noScroll);
    });
    EventBus.$on("close-contact", () => {
      this.showContact = false;
      window.removeEventListener("scroll", this.noScroll);
    });
    EventBus.$on("show-qr-code", () => {
      $('.overlay-qr-code').addClass('show');
    });
  },
  methods: {
    noScroll() {
      window.scrollTo(0, 0);
    },
    closeContact() {
      EventBus.$emit("close-contact");
    },
    showQRcode() {
      $('.overlay-qr-code').addClass('show');
    },
    hideQRcode() {
      $('.overlay-qr-code').removeClass('show');
    },
  }
};
</script>
