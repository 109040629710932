import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import VueHead from 'vue-head'
import VueCookies from 'vue-cookies'
import VeeValidate from 'vee-validate'
import Axios from 'axios'
import wysiwyg from "vue-wysiwyg";

Vue.config.productionTip = false

Axios.defaults.baseURL = "http://192.168.31.16:2403"

Vue.use(VueHead)
Vue.use(VeeValidate)
Vue.use(VueCookies)
Vue.use(wysiwyg, {
  hideModules: { "image": true, "table": true, "separator": true },
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
