const about_us_en = {
    aboutUsPageHeader: {
        title: 'ABOUT US',
        paragraph1: "Aezypay Pte. Ltd. is a company that creates products that are secure, reliable and have an advanced digital asset trading platform developed for international customers and built on Digital Valley's cutting-edge technology.",
        paragraph2: "This international trading platform provides innovative tokens a more streamlined approach, providing an instant trade execution, dependable digital wallets, and industry-leading security practices.",
    },
    ourCoreValues: {
        title1: 'OUR MISSION',
        paragraph1: 'We are committed to being a driving force in the blockchain revolution by increasing adoption of this innovative technology around the world.',
        title2: 'OUR VISION',
        paragraph2: 'Aezypay pte. Ltd. will be a globally accepted method of exchanging and storing value.',
        title3: 'OUR VALUES',
        paragraph3: 'To provide our consumers with the most privacy and highest guaranteed financial investment wallet.',
        title4: 'OUR BELIEFS',
        paragraph4: 'Lorem ipsum dolor sit amet, consectetur adipiscing elite, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    },
    ourTeam: {
        headerTitleFull: 'OUR TEAM',
        headerTitle1: 'OUR',
        headerTitle2: 'TEAM',

        title1: 'TECHNICAL',
        paragraph1: 'The technical team is composed of technical elites who are known as network security experts, and has the top network security support from the United States. Based on the mission of user asset security, the company is responsible for product security and stability, and fully protects the security of users and merchants. Provide a safe, professional, and smooth experience.',
        title2: 'RISK CONTROL',
        paragraph2: 'The risk control team comes from the elites in the banking and stock securities fields. Among them, the expert consultants with more than 15 years of experience in risk control work use the scientific, rigorous and thorough risk control auditing standards and methods to comprehensively verify the real information of the trading station customers, trading risk analysis, customer transaction level review, digital asset control ability assessment, to the greatest extent possible to prevent risks.',
        title3: 'PRODUCT',
        paragraph3: 'The product team is an industry elite with well-known national qualifications. The team members have high business capabilities, and develop long-term blockchain digital assets, combined with exisiting business resources, to create perfect products.',
        title4: 'CUSTOMER SERVICE',
        paragraph4: 'The customer service team provides high-quality services to users 24 hours a day, adhering to the principle of customer first, customer first, responsible for solving all kinds of difficult problems for users, serving customers wholeheartedly and maintaining 7*24 hours service status.',
    },
    ourPartners: {
        headerTitle: 'OUR PARTNERS',
        headerParagraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        headerButton: 'Want to work with us?',

        title1: 'PHILIPPINES YATAI CITY',
        paragraph1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.',
        title2: 'MYANMAR YATAI CITY',
        paragraph2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.',
        title3: 'HONGKONG DATA CENTER',
        paragraph3: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.',
        title4: 'SINGAPORE 66',
        paragraph4: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.',
    },
}

const about_us_cn = {
    aboutUsPageHeader: {
        title: '关于公司',
        paragraph1: "Aezypay Pte. Ltd 有限公司是一家创建安全，可靠的产品的公司，拥有为国际客户开发的先进数字资产交易平台，并以Digital Valley的尖端技术为基础。 ",
        paragraph2: "这个国际交易平台为创新的令牌提供了更加简化的方法，提供即时交易执行，可靠的数字钱包和行业领先的安全实践。",
    },
    ourCoreValues: {
        title1: '我们的任务',
        paragraph1: '我们致力于通过在全球范围内更多地采用这种创新技术，成为区块链革命的推动力量。',
        title2: '我们的视力',
        paragraph2: 'Aezypay 有限公司将是一个全球公认的交换和存储价值的方法。',
        title3: '我们的价值观',
        paragraph3: '为我们的消费者提供最隐私和最有保障的金融投资钱包。',
        title4: '我们的信仰',
        paragraph4: 'Lorem ipsum dolor sit amet, consectetur adipiscing elite, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    },
    ourTeam: {
        headerTitleFull: '我们的团队',
        headerTitle1: '我们的',
        headerTitle2: '团队',

        title1: '技术团队',
        paragraph1: '技术团队由技术精英组成，他们被称为网络安全专家，并获得了美国的顶级网络安全技术支持。基于用户资产安全的使命，公司负责产品的安全性和稳定性，充分保护用户和商家的安全。提供安全，专业，顺畅的体验。',
        title2: '风险控制球队',
        paragraph2: '风险控制团队来自银行和股票证券领域的精英。其中，具有超过15年风险控制工作经验的专家顾问使用科学，严谨，彻底的风险控制审计标准和方法，全面验证交易站客户的实际信息。 ，交易风险分析，客户交易水平审查，数字资产控制能力评估，最大限度地防范风险。',
        title3: '产品团队',
        paragraph3: '产品团队是具有国家知名资质的行业精英。团队成员具有较高的业务能力，并开发长期的区块链数字资产，结合现有业务资源，以创建完美的产品。',
        title4: '客户服务',
        paragraph4: '客户服务团队全天24小时为用户提供优质服务，秉承客户至上，客户至上的原则，负责为用户解决各种疑难问题，全心全意为客户服务，维护7 * 24小时服务状态。',
    },
    ourPartners: {
        headerTitle: '我们的伙伴',
        headerParagraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        headerButton: '想与我们合作？',

        title1: 'PHILIPPINES YATAI CITY',
        paragraph1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.',
        title2: 'MYANMAR YATAI CITY',
        paragraph2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.',
        title3: 'HONGKONG DATA CENTER',
        paragraph3: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.',
        title4: 'SINGAPORE 66',
        paragraph4: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.',
    },
}

export { about_us_en, about_us_cn } 