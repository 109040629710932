<template>
    <div class="home page">
        <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
        <SectionIntroduction />
        <SectionHighlights />
        <SectionAboutCompany />
        <SectionDetails />
        <SectionFeatures />
        <SectionChart />
        <SectionNews />
        <Footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import SectionIntroduction from "@/components/home/SectionIntroduction.vue";
import SectionHighlights from "@/components/home/SectionHighlights.vue";
import SectionAboutCompany from "@/components/home/SectionAboutCompany.vue";
import SectionDetails from "@/components/home/SectionDetails.vue";
import SectionFeatures from "@/components/home/SectionFeatures.vue";
import SectionChart from "@/components/home/SectionChart.vue";
import SectionNews from "@/components/home/SectionNews.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "home",
  head: {
    title: { inner: "Home" }
  },
  components: {
    SectionIntroduction,
    SectionHighlights,
    SectionAboutCompany,
    SectionFeatures,
    SectionDetails,
    SectionChart,
    SectionNews,
    Footer
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // this.$refs.sectionDetails.handleScroll();
    }
  }
};
</script>

<style lang="scss">
@import "../assets/css/project.scss";
</style>

