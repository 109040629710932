<template>
  <div class="contact-methods">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-10 col-lg-8 offset-md-2">
          <div class="header">
            <h2 class="title">{{ $t('contactUs.title') }}</h2>
            <!-- <p class="description">{{ description }}</p> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col offset-sm-2 methods">
          <img src="@/assets/img/email.svg">
          <p><a class="methods-link" href="mailto:contact@aezypay.com">contact@aezypay.com</a></p>
        </div>
      </div>
      <div class="row">
        <div class="col offset-sm-2 methods">
          <img src="@/assets/img/telephone-fax.svg">
          <p><a class="methods-link" href="tel:+6569091310">
            Telephone: +65 6909 1310
            <!-- <br>Fax: +65 9876 5432 --></a>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col offset-sm-2 methods">
          <img src="@/assets/img/location.svg">
          <p><a class="methods-link" href="https://goo.gl/maps/5rcqPEeTdQvnzoAG7" target="_blank">
            140 Robinson Road #16-00
            <br>Singapore 068907</a>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col offset-sm-2 methods">
          <img style="width: 16px; position: relative; right: -4px;" src="@/assets/img/fb-contact.svg">
          <p><a class="methods-link" href="https://www.facebook.com/asiaezypay" target="_blank">Find us on Facebook!</a></p>
        </div>
      </div>
      <div class="row">
        <div class="col offset-sm-2 methods">
          <img style="width: 15px; position: relative; right: -5px;" src="@/assets/img/linkedin-contact.svg">
          <p><a class="methods-link" href="https://www.linkedin.com/company/asia-ezypay" target="_blank">Connect with us on LinkedIn!</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data: function() {
    return {
      title: "Contact Us",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  }, 

};
</script>

<style lang="scss" scoped>

$icon-size: 13%;

.contact-methods {
  display: none;
  height: calc(100vh - 156px); 

  @media (max-width: 767.98px) {
    height: auto;
    margin-bottom: 60px; 
  }

  .header {

    .title {
      font-size: 23px;
      font-weight: 700;
      background: -webkit-linear-gradient(#8089a2, #607b96, #2a346b);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;
      margin-top: 24px;

      @media (max-width: 767.98px) {
        text-align: justify;
      }
    }

    .description{
      padding: 24px 0;
      color: #3f599a;
      text-align: left;

      @media (min-width: 768px) and (max-width: 991.98px) {
        text-align: justify;
      }
    }
  }

  .methods {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    margin-top: 25px;
    margin-bottom: 25px;

    p {
      margin-left: 30px;
      margin-bottom: 0;
      font-size: 13px;
      text-align: left;
      color: rgba(63,89,154,0.8);
    }

    a {
      color: rgba(63,89,154,0.8);
    }
  }
}
</style>