<template>
    <div :class="bidentId" class="bident">
        <div class="overflow">
            <div :class="direction"></div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
      bidentId: String,
      direction: String,
  }
};
</script>

<style lang="scss" scoped>
    .bident {
        position: absolute;
        height: 2px;
        background-color: #333;        

        .bident-left,
        .bident-right {
            position: absolute;
            width: 100px;
            height: 100px;
            // top: -50px;
            border-radius: 50%;
            border: 3px solid #333;
            // @include box-shadow(0 0 5px rgba($bident-color, 0.6));

            &::before {
                content: '';
                position: absolute;
                background-color: #333;
                width: 53px;
                height: 116px;
                top: -12px;
            }

        }
        
        .bident-left {
            left: -100px;

            &::before {
                left: -7px;
            }
        }

        .bident-right {
            right: -100px;

            &::before {
                right: -7px;
            }
        }
    }
</style>
