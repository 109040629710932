import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { main_nav_cn, main_nav_en } from '@/locales/main-nav';
import { home_cn, home_en } from '@/locales/home';
import { about_us_cn, about_us_en } from '@/locales/about-us'
import { szg_cn, szg_en } from '@/locales/szg';
import { secret_en, secret_cn } from '@/locales/secret';
import { news_and_announcements_cn, news_and_announcements_en } from '@/locales/news-and-announcements';
import { single_news_and_announcements_cn, single_news_and_announcements_en } from '@/locales/single-news-and-announcements';
import { careers_cn, careers_en } from '@/locales/careers';

Vue.use(VueI18n)

const messages = {
  English: {
    ...main_nav_en,
    ...home_en,
    ...about_us_en,
    ...szg_en,
    ...secret_en,
    ...news_and_announcements_en,
    ...single_news_and_announcements_en,
    ...careers_en,
  }, 
  简体中文: {
    ...main_nav_cn,
    ...home_cn,
    ...about_us_cn,
    ...szg_cn,
    ...secret_cn,
    ...news_and_announcements_cn,
    ...single_news_and_announcements_cn,
    ...careers_cn,
  }
}

export default new VueI18n({
  locale: 'English',
  messages: messages,
});