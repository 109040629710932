<template>
    <div>
        <div class="spacing-section-features"></div>
        <section class="section-features-header">
            <h2 class="title">{{ $t('features.headerTitle') }}</h2>
            <p class="description">{{ $t('features.headerDescription') }}</p>
        </section>
        <div class="section-features-overlaying-contents">
            <!-- IMPORTANT: Tridents need to be done closer to the end of the completion of the page in XL viewport -->
            <!-- Overlaying tridents, circles, logos, phones --> 

            <!-- Mobile only -->

            <div class="phones"></div>

            <div class="circles">
                <div class="icon icon-privacy-security animated invisible slow"></div>
                <div class="icon icon-blockchain-tech animated invisible slow"></div>
                <div class="icon icon-financial-security animated invisible slow"></div>
            </div>

            
            <!-- End of overlaying tridents, circles, logos, phones -->
        </div>
        <section class="section-features">
            <div class="trident trident-1 animated invisible slow">
                <div class="trident-left"></div>
            </div>

            <div class="trident trident-2 animated invisible slow">
                <div class="trident-left"></div>
            </div>

            <div class="trident trident-3 animated invisible slow">
                <div class="trident-left"></div>
            </div>

            <div class="trident trident-4 animated invisible slow">
                <div class="trident-right"></div>
            </div>

            <div class="trident trident-5 animated invisible slow">
                <div class="trident-right"></div>
            </div>

            <div class="trident trident-6 animated invisible slow">
                <div class="trident-right"></div>
            </div>
            <div class="row">
                <div class="offset-md-1 col-12 col-md-5 col-xl-2 left-col"> 
                    <TextBlock class="animated invisible slow" :icon="'home-section-features/user-information-security.svg'" :title="$t('features.title1')" :description="$t('features.description1')"/>
                    <TextBlock class="animated invisible slow" :icon="'home-section-features/blockchain-technology.svg'" :title="$t('features.title2')" :description="$t('features.description2')"/>
                    <TextBlock class="animated invisible slow" :icon="'home-section-features/financial-security.svg'" :title="$t('features.title3')" :description="$t('features.description3')"/>
                </div>
                <div class="offset-xl-6 col-12 col-md-5 col-xl-2 right-col">
                    <TextBlock class="animated invisible slow" :icon="'home-section-features/multiple-payment-channels.svg'" :title="$t('features.title4')" :description="$t('features.description4')"/>
                    <TextBlock class="animated invisible slow" :icon="'home-section-features/various-payment-scenarios.svg'" :title="$t('features.title5')" :description="$t('features.description5')"/>
                    <TextBlock class="animated invisible slow" :icon="'home-section-features/global-currency-conversion.svg'" :title="$t('features.title6')" :description="$t('features.description6')"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import $ from 'jquery';
import TextBlock from '@/components/partials/TextBlock.vue';
import { setTimeout } from 'timers';

export default {
    name: "SectionFeatures",
    props: {
    },
    components: {
        TextBlock,
    },
    mounted: function() {
        
        var vw = window.innerWidth;
    
        if (vw < 992) { // if viewport is mobile
            
        } else { // if viewport is tablet and larger

            class ChainFunctions {
                animateLeftCol() {
                    $('.left-col .animated').each((i, element) => {
                        setTimeout(() => {
                            $(element).removeClass('invisible').addClass('fadeInRight');
                        }, 500*i);
                    });

                    return this;
                }

                animateRightCol() {
                    $('.right-col .animated').each((i, element) => {
                        setTimeout(() => {
                            $(element).removeClass('invisible').addClass('fadeInLeft');
                        }, 500*i);
                    });

                    return this;
                }

                hideLeftCol() {
                    $('.left-col .animated').removeClass('fadeInRight').addClass('invisible');
                    
                    return this;
                }

                hideRightCol() {
                    $('.right-col .animated').removeClass('fadeInLeft').addClass('invisible');
                    
                    return this;
                }

                showIcons() {
                    $('.icon.animated').each((i, element) => {
                        setTimeout(() => {
                            $(element).removeClass('invisible').addClass('bounceIn');
                        }, 500*i);
                    });
                    
                    return this;
                }

                hideIcons() {
                    $('.icon.animated').removeClass('bounceIn').addClass('invisible');

                    return this;
                }

                showTridents() {
                    setTimeout(() => {
                        $('.trident.animated').removeClass('invisible').addClass('fadeIn');
                    }, 2000);

                    return this;
                }

                hideTridents() {
                    $('.trident.animated').removeClass('fadeIn').addClass('invisible');

                    return this;
                }
            }

            let chainFunctions = new ChainFunctions();
        
            $.fn.isInViewport = function() {
                var elementTop = $(this).offset().top;
                var elementBottom = elementTop + $(this).outerHeight();

                var viewportTop = $(window).scrollTop();
                var viewportBottom = viewportTop + $(window).height();

                return elementBottom > viewportTop && elementTop < viewportBottom;
            };
            
            // if ($('.section-features').isInViewport()) {
                
            $(window).on('scroll', function() {
                if ($('.section-features').isInViewport()) {
                    chainFunctions.showIcons()
                    .animateLeftCol()
                    .animateRightCol()
                    .showTridents();
                } else {
                    chainFunctions.hideIcons()
                    .hideLeftCol()
                    .hideRightCol()
                    .hideTridents();
                }
            });
        }
    },
    methods: {
        animateLeftCol() {
            $('.left-col .animated').each((i, element) => {
                setTimeout(() => {
                    $(element).removeClass('invisible').addClass('fadeInRight');
                }, 500*i);
            });

            return this;
        },

        animateRightCol() {
            $('.right-col .animated').each((i, element) => {
                setTimeout(() => {
                    $(element).removeClass('invisible').addClass('fadeInLeft');
                }, 500*i);
            });

            return this;
        },

        hideLeftCol() {
            $('.left-col .animated').removeClass('fadeInRight').addClass('invisible');
            
            return this;
        },

        hideRightCol() {
            $('.right-col .animated').removeClass('fadeInLeft').addClass('invisible');
            
            return this;
        },

        showIcons() {
            $('.icon.animated').each((i, element) => {
                setTimeout(() => {
                    $(element).removeClass('invisible').addClass('bounceIn');
                }, 500*i);
            });
            
            return this;
        },

        hideIcons() {
            $('.icon.animated').removeClass('bounceIn').addClass('invisible');

            return this;
        },

        showTridents() {
            setTimeout(() => {
                $('.trident.animated').removeClass('invisible').addClass('fadeIn');
            }, 2000);

            return this;
        },

        hideTridents() {
            $('.trident.animated').removeClass('fadeIn').addClass('invisible');

            return this;
        }
    },
    // beforeDestroy: function() {
    //     this.isInViewport = null;
    //     delete this.isInViewport;

    //     this.animateLeftCol = null;
    //     delete this.animateLeftCol;

    //     this.animateRightCol = null;
    //     delete this.animateRightCol;

    //     this.hideLeftCol = null;
    //     delete this.hideLeftCol;

    //     this.hideRightCol = null;
    //     delete this.hideRightCol;

    //     this.showIcons = null;
    //     delete this.showIcons;

    //     this.hideIcons = null;
    //     delete this.hideIcons;

    //     this.showTridents = null;
    //     delete this.showTridents;

    //     this.hideTridents = null;
    //     delete this.hideTridents;
    // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../../assets/css/scss/home/section-features.scss';
</style>
