const main_nav_en = {
	mainNav: {
		home: 'HOME',
        aboutUs: 'ABOUT US',
        products: 'PRODUCTS',
        szg: 'DIGITAL VALLEY',
        // goodb: 'GOODB',
        // bibao: 'BIBAO',
        secret: 'SECRET',
		news: 'NEWS',
		careers: 'CAREERS',
	},
}

const main_nav_cn = {
	mainNav: {
		home: '主页',
        aboutUs: '关于公司',
        products: '产品',
        szg: '数字谷',
        // goodb: '好币',
        // bibao: '币宝',
        secret: 'SECRET',
		news: '新闻和公告',
		careers: '职业招聘',
	},
}

export { main_nav_en, main_nav_cn } 