<template>
  <div class="contact-us page">
    <div class="close-btn">
      <img src="@/assets/img/contact-us-close-button.png" alt="close button" @click="closeContact">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <Methods/>
        </div>
        <div class="col-12 col-md-6">
          <Form/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";

import Methods from "@/components/contact-us/ContactMethods.vue";
import Form from "@/components/contact-us/ContactForm.vue";

export default {
  name: "contact-us",
  // head: {
  //   title: { inner: "Contact Us" }
  // },
  components: {
    Methods,
    Form
  },
  methods: {
    closeContact() {
      EventBus.$emit("close-contact");
    }
  }
};
</script>

<style lang="scss" scoped>

$button-size: 7.5%;
.contact-us {
  height: 100vh;

  @media (max-width: 767.98px) {
    position: fixed;
    overflow-y: scroll;
  }
  
  .close-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
    position: absolute;
    z-index: 100;
    right: 15px;
    visibility: hidden;

    img {
      margin-right: 15px;
      margin-top: 15px;
      width: $button-size;
      height: $button-size;

      @media (max-width: 767.98px) {
        width: 12.5%;
        height: 12.5%;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.open .close-btn {
    right: 0;
    visibility: visible;
  }
}
</style>

