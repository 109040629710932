<template>
  <div class="contact-form">
    <div class="container-fluid">

      <div v-if="!formSubmitted"> <!-- Before Form Submission -->

        <div class="row">
          <div class="col-12 col-md-10 col-lg-8 offset-lg-2">
            <div class="header">
              <h2 class="text-left title">{{ $t('contactUs.formTitle') }}</h2>
              <p class="text-left description">{{ $t('contactUs.formDescription') }}</p>
            </div>
          </div>
        </div>

      <!-- contact form -->
        <div class="row">
          <div class="col-12 col-md-10 col-lg-8 offset-lg-2">
            <form ref="contact" @submit.prevent="onSubmit">
              <div
                v-for="input in formInputs"
                class="form-group"
                :class="{'error-show': errors.has(input.id) }"
                :key="input.id"
              >
                <label :for="input.id">
                  {{ input.label }}
                  <span v-if="input.rules.required">*</span>
                </label>
                <input
                  class="form-control"
                  :type="input.type"
                  :id="input.id"
                  :aria-describedby="input.aria"
                  :placeholder="input.placeholder"
                  :name="input.id"
                  v-model.trim="form[input.id]"
                  v-validate="input.rules"
                >
                <!-- requestCall -->
                <div class="form-check" v-if="input.requestCall">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value
                    id="requestCall"
                    ref="call"
                    v-model="form.requestCall"
                  >
                  <label class="form-check-label" for="requestCall">Request a call back</label>
                </div>

                <!-- error message -->
                <small
                  class="form-text error-msg"
                  v-show="errors.has(input.id)"
                >{{ errors.first(input.id) }}</small>
              </div>
              <button type="submit" class="btn btn-primary" :class="{'ready': errors.all().length ===0 }" :disabled="errors.all().length !==0">Send</button>
            </form>
          </div>
        </div>

      </div>
      <div v-else> <!-- Before Form Submission -->
        <div class="grid-form-submitted">
          <img class="img-form-submitted" src="@/assets/img/contactus-success.png" />
          <h2 class="title-form-submitted">THANK YOU!</h2>
          <p class="message-form-submitted">Your message has been sent</p>

          <button class="btn-form-submitted" @click.prevent="resetForm">Send another message</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  head: {
    script: [{ type: "text/javascript", src: "https://smtpjs.com/v3/smtp.js" }]
  },
  data: function() {
    return {
      title: "How Can We Help You?",
      description: "Talk To Us!",
      formSubmitted: false,
      formInputs: [
        {
          type: "text",
          id: "name",
          aria: "name",
          label: "Name",
          placeholder: "First and Last Name",
          rules: {
            required: true
          }
        },
        {
          type: "email",
          id: "email",
          aria: "email",
          label: "Email",
          placeholder: "example@email.com",
          rules: {
            email: true,
            required: true
          }
        },
        {
          type: "text",
          id: "contactNumber",
          aria: "contactNumber",
          label: "Contact Number",
          placeholder: "+<country code> 0000 0000",
          requestCall: true,
          rules: {
            required_if: "call,true", 
            regex: /^\+[0-9]+$/
          }
        },
        {
          type: "text",
          id: "message",
          aria: "message",
          label: "Message",
          placeholder: "Please type your message",
          rules: {}
        }
      ],
      form: {
        name: null,
        email: null,
        contactNumber: null,
        requestCall: false,
        message: null
      }, 
    };
  },
  computed: {},
  methods: {
    resetForm() {
      this.formSubmitted = false;
    },
    async postMessage() {
      await axios
        .post("/feedback", this.form)
        .then(res => console.log("success"))
        .catch(err => console.log(error));
    },
    onSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
          // this.postMessage();
          this.sendEmail(this.form);
          this.formReady=true
          this.formSubmitted = true;
          return 
        }
        console.log("errors");
      });
    },
    sendEmail(form) {
      console.log("sending contact email");
      Email.send({
        Host: "smtp.gmail.com",
        Username: "frontend.aep@gmail.com",
        Password: "kbmejcieybqiqgoa",
        To: "contact@aezypay.com",
        From: "frontend.aep@gmail.com",
        Subject: "Message From Website" + form.name,
        Body: `
          <h1>Hello</h1>
          <div>
            Name: ${form.name}<br>
            Email: ${form.email}<br>
            Contact Number: ${form.contactNumber}<br>
            Request Callback: ${form.requestCall ? 'Yes' : 'No'}<br>
            Message: ${form.message}
          </div>
        `
      }).then(message => {
        if(message === "OK"){
          console.log('success')
        }else{
          alert(message)
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

$text-color: #3f599a;
$placeholder-color: rgba(#bbbdbf, 0.8);
$input-line-color: linear-gradient(to right, #2a346b, #607b96, #8089a2);

.contact-form {
  display: none;
  height: calc(100vh - 156px);
  .header {
    .title {
      font-size: 23px;
      font-weight: 700;
      background: -webkit-linear-gradient(#8089a2, #607b96, #2a346b);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 24px;
    }
    .description {
      padding: 24px 0;
      color: $text-color;
    }
  }

  form {
    text-align: left;

    .form-control {
      @media (max-width: 767.98px) {
        padding-left: 0;
      }
    }

    .form-group {
      color: $text-color;
      margin-bottom: 53px;
      text-align: left;
      @media (max-width: 767.98px) {
        margin-bottom: 33px;
      }

      label {
        outline: none;
      }

      input[type="text"],
      input[type="email"] {
        border: none;
        outline: none;
        border-bottom: 1px solid $text-color;
        border-radius: 0;
        box-shadow: none;

        &:focus {
          border-bottom: 1px solid $text-color;
        }
      }

      .form-check-label {
        color: rgba(#3f599a, 0.5);
        font-size: 14px;
      }

      ::-webkit-input-placeholder {
        color: $placeholder-color;
        font-size: 13px;
      }
      ::-moz-placeholder {
        color: $placeholder-color;
        font-size: 13px;
      }
      :-ms-input-placeholder {
        color: $placeholder-color;
        font-size: 13px;
      }
      ::placeholder {
        color: $placeholder-color;
        font-size: 13px;
      }
      .error-msg {
        color: red;
      }
    }

    button[type="submit"] {
      // background: linear-gradient(to right, #bfc3d0, #aab8c6, #9498b4);
      background-color: #d0d2d3;
      // background: linear-gradient(to right, #2A346C, #5F7A95, #7E88A2);
      border: none;
      outline: none;
      border-radius: 10px;
      padding: 5px 50px;
      cursor: default;

      @media (max-width: 767.98px) {
        margin-bottom: 30px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    // apply to form-group
    .error-show {
      input[type="text"],
      input[type="email"] {
        border-bottom: 1px solid red;
        &:focus {
          border-bottom: 1px solid red;
        }
      }
    }
    // apply to form
    .ready {
        background: linear-gradient(to right, #2a346c, #5f7a95, #7e88a2);
    }
  }
}

.grid-form-submitted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  .img-form-submitted {
    width: 254px;
    height: 254px;
    margin-top: 60px;
  }

  .title-form-submitted {
    font-size: 2.6rem;
    color: rgb(63, 89, 154);
    margin-bottom: 10px;
  }

  .message-form-submitted {
    font-size: 13px;
    color: rgba(63, 89, 154, 0.8);
    margin-bottom:40px;
  }

  .btn-form-submitted {
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 5px 50px;
    cursor: default;
    font-size: 13px;
    color: white;
    background: -webkit-gradient(linear, left top, right top, from(#2a346c), color-stop(#5f7a95), to(#7e88a2));
    background: linear-gradient(to right, #2a346c, #5f7a95, #7e88a2);
  }
}
</style>
