<template>
  <div class="news-card" :style="'background-image: url(' + imgUrl + ')'">
      <div class="overlay">
        <h3 class="title">{{ title }}</h3>
        <p class="paragraph">{{ paragraph }}</p>
        <router-link class="read-more"  to="/single-news-and-announcements/">Read more... </router-link>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
import MultiClamp from 'multi-clamp';

export default {
  name: "NewsCard",
  props: {
    title: String,
    paragraph: String,
    img: String
  },
  computed: {
    imgUrl: function() {
      return require ('../../assets/img/archive-news-and-announcements/' + this.img);
    }
  },
  created: function() {
    $(document).ready(() => {
        $('.news-card .title').each(function(index, element) {
            new MultiClamp(element, {
                ellipsis: '...',
                clamp: 2
            });
        });

        $('.news-card .paragraph').each(function(index, element) {
            new MultiClamp(element, {
                ellipsis: '...',
                clamp: 4
            });
        });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/scss/news-card.scss";


.news-card:hover .overlay {
  height: 50%;
}

.news-card {
  @media (max-width: 1199.98px) {
    .read-more{
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .read-more {
      &:hover {
          text-decoration: none;
      }
    }
    .read-more {
      font-size: 14px;
      font-weight: 100;
      color: white;
      opacity: 0;
      -webkit-transition: opacity 0.4s;
      transition: opacity 0.4s;

      @media (max-width: 991.98px) {
          opacity: 1;
      }
    }  
    &:hover {
      .read-more {
          opacity: 1;
      }
    }
  }
}

</style>

