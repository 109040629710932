import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history', // no hash tag mode
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', name: 'home', component: Home },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },

    { path: '/about-us', name: 'about-us', component: () => import('./views/About-Us.vue') },
    { path: '/szg', name: 'szg', component: () => import('./views/Szg.vue') },
    { path: '/secret', name: 'secret', component: () => import('./views/Secret.vue') },
    { path: '/contact-us', name: 'contact-us', component: ()=> import('./views/Contact-Us.vue') }, // to be removed
    { path: '/archive-news-and-announcements', name: 'archive-news-and-announcements', component: () => import('./views/Archive-News-and-Announcements.vue')},
    { path: '/single-news-and-announcements/:id', name: 'single-news-and-announcements', component: () => import('./views/Single-News-and-Announcements.vue')},
    { path: '/careers', name: 'careers', component: () => import('./views/Careers.vue')},
    { path: '/components', name: 'components', component: ()=> import('./views/Components.vue')},

    { path: '/login', name: 'login', component: () => import('@/views/Login.vue') },
    { path: '/resetPassword/:id', name: 'resetPassWord', component: () => import('@/views/ResetPassword.vue') },
    // Admin
    {
      path: '/admin', component: () => import('@/views/admin/Admin.vue'),
      beforeEnter: (to, from, next) => {
        if (window.$cookies.isKey('username')) {
          next()
        } else {
          next({ path: '/login' })
        }
      },
      children: [
        { path: 'careers', name: 'admin-careers', component: () => import('@/views/admin/Careers.vue') },
        { path: 'careers/new', name: 'admin-careers-new', component: () => import('@/views/admin/CareersNew.vue') },
        { path: 'careers/:id', name: 'admin-careers-edit', component: () => import('@/views/admin/CareersEdit.vue') },
        { path: 'announcements', name: 'admin-announcements', component: () => import('@/views/admin/Announcements.vue') },
        { path: 'announcements/new', name: 'admin-announcements-new', component: () => import('@/views/admin/AnnouncementsNew.vue') },
        { path: 'announcements/:id', name: 'admin-announcements-edit', component: () => import('@/views/admin/AnnouncementsEdit.vue') },
        { path: '', name: 'admin-home', component: ()=> import('@/views/admin/AdminHome.vue') }, // to update Admin Home Page
      ]
    },
    
    { path: '/404', name: '404', component: ()=> import('./views/404.vue')},
    
    // { path: '*', name: 'notFound'},
    { path: '/*', redirect: { name: '404' }},
    { path: '*', redirect: { name: '404' }}
  ]
})