<template>
  <button class="roundbtn">{{ text }}</button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>

.roundbtn {
  cursor: pointer;
  position: relative;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.15;
  padding: 1rem 2rem; // needs revisit
  background-color: rgba(#28316f, 0.4);
  border: 1px solid rgba(#28316f, 0.035);
  border-radius: 2rem; // needs revisit
  text-shadow: 0 0 10px #333;
  color: white;
  text-transform: uppercase;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(#333, 0.45);
  box-shadow: 5px 5px 10px 0px rgba(#333, 0.45);
  outline: none;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;

  &:hover {
    background-color: #28316f;
  }
  &:active {
    background-color: #28316f;
  }
  &:focus {
    outline: none;
  }
}
</style>
