<template>
    <div class="row section-introduction">
      <!-- Overlaying contents for the background -->
        <div class="overlaying-contents">
          
          <div class="circles">
            <div class="circle circle-1"></div>
            <div class="circle circle-2"></div>
            <div class="circle circle-3"></div>
            <div class="circle circle-4"></div>
          </div>
          <div class="rings rings-1">
            <div class="ring inner-ring-1"></div>
            <div class="ring inner-ring-2"></div>
            <div class="ring inner-ring-3"></div>
            <div class="ring inner-ring-4"></div>
          </div>
          <div class="rings rings-2">
            <div class="ring inner-ring-1"></div>
            <div class="ring inner-ring-2"></div>
            <div class="ring inner-ring-3"></div>
            <div class="ring inner-ring-4"></div>
          </div>
          <div class="rings rings-3">
            <div class="ring inner-ring-1"></div>
            <div class="ring inner-ring-2"></div>
            <div class="ring inner-ring-3"></div>
            <div class="ring inner-ring-4"></div>
          </div>
          <div class="rings rings-4">
            <div class="ring inner-ring-1"></div>
            <div class="ring inner-ring-2"></div>
            <div class="ring inner-ring-3"></div>
            <div class="ring inner-ring-4"></div>
          </div>
          <div class="line line-1"></div>
          <div class="line line-2"></div>
          <div class="line line-3"></div>
          <div class="line line-4"></div>
          <div class="icons">
            <div class="icon icon-transparent">
              <img class="image" src="../../assets/img/transparent-disclosure-section-introduction.svg" />
              <div class="divider"></div>
              <p class="description">{{ $t('introduction.iconDescription1') }}</p>
            </div>
            <div class="icon icon-global">
              <img class="image" src="../../assets/img/globe-section-introduction.svg" />
              <div class="divider"></div>
              <p class="description">{{ $t('introduction.iconDescription2') }}</p>
            </div>
            <div class="icon icon-security">
              <img class="image" src="../../assets/img/security-section-introduction.svg" />
              <div class="divider"></div>
              <p class="description">{{ $t('introduction.iconDescription3') }}</p>
            </div>
            <div class="icon icon-premium">
              <img class="image" src="../../assets/img/premium-currency-section-introduction.svg" />
              <div class="divider"></div>
              <p class="description">{{ $t('introduction.iconDescription4') }}</p>
            </div>
          </div>
          <div class="phone-outline"></div>
          <div class="phone"></div>

        </div>

        <!-- Navbar: Not the right way to do it, will require refactoring -->
        <MainNav />

        <!-- Content -->
        <div class="offset-md-1 col-12 col-md-10 col-xl-4 content">
          <h2 class="title">{{ $t('introduction.titleLine1') }} <br class="d-lg-none d-xl-block" />{{ $t('introduction.titleLine2') }}</h2>
          <p class="description">{{ $t('introduction.description') }}</p>

          <!-- Button: Toggle visibility based on screen size -->
          <!-- <div class="rounded-button d-none d-lg-block d-xl-none">
            <RoundedButton :text="$t('introduction.buttonText')" />
          </div> -->

          <p class="paragraph">{{ $t('introduction.paragraph') }}</p>
          
          <!-- Button: Toggle visibility based on screen size -->
          <div class="rounded-button d-none d-lg-block">
            <RoundedButton :text="$t('introduction.buttonText')" />
          </div>
        </div>

    </div>
</template>

<script>
import MainNav from '@/components/MainNav.vue';
import RoundedButton from '@/components/partials/RoundedButton.vue';

export default {
  name: "SectionIntroduction",
  components: {
    MainNav,
    RoundedButton,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../../assets/css/scss/home/section-introduction.scss';
</style>
