<template>
  <section class="section-highlights">
    <div class="overflow-usp-group">
      <div class="usp-section-home bg-gray">
        <div class="usp-group">
          <usp title="highlights.title1" description="highlights.msg1"/>
          <usp title="highlights.title2" description="highlights.msg2"/>
          <usp title="highlights.title3" description="highlights.msg3"/>
          <usp title="highlights.title4" description="highlights.msg4"/>
        </div>
        <div class="white-overlay">
          <div class="oval"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import usp from '@/components/partials/HighlightCircles.vue';

export default {
  name: "SectionHighlight",
  components: {
    usp
  },
  data: function(){
    return{

    }
  }
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../../assets/css/scss/home/section-highlights.scss';
</style>
