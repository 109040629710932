<template>
    <div class="offset-xl-1 col-xs-12 col-xl-10">
        <nav id="main-nav" class="main-nav navbar navbar-expand-lg">

          <router-link class="navbar-brand"  to="/">
            <img src="../assets/img/logo.png" class="logo">
          </router-link>

          <div class="functions d-lg-none">
            <span class="dropdown dropdown-language">
              <img class="link-icon-language dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="../assets/img/language.svg" />
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#">English</a>
                <a class="dropdown-item" href="#">简体中文</a>
              </div>
            </span>
            
            <img @click="openContact" class="link-icon-contact-us" src="../assets/img/contact-us.svg" />

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <img class="navbar-toggler-icon" src="@/assets/img/nav-menu.svg" />
            </button>
          </div>

          <div class="collapse navbar-collapse" id="navbarNav">

            <img class="close-button" src="@/assets/img/main-nav/icon-close-button.svg" />

            <div class="language-selector">
              <span v-for="(lang, i) in langs" :key="`Lang${i}`" class="language" @click="changeLanguage(lang)" :data-language="lang">{{ lang }}</span>
            </div>

            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <router-link class="nav-link" to="/">
                  <span class="icon">
                    <img class="icon-blue" src="@/assets/img/main-nav/icon-home-blue.svg"/>
                    <img class="icon-white" src="@/assets/img/main-nav/icon-home-white.svg"/>
                  </span>
                  {{ $t('mainNav.home') }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link"  to="/about-us">
                  <span class="icon">
                    <img class="icon-blue" src="@/assets/img/main-nav/icon-about-us-blue.svg"/>
                    <img class="icon-white" src="@/assets/img/main-nav/icon-about-us-white.svg"/>
                  </span>
                  {{ $t('mainNav.aboutUs') }}
                </router-link>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="icon">
                    <img class="icon-blue" src="@/assets/img/main-nav/icon-products-blue.svg"/>
                    <img class="icon-white" src="@/assets/img/main-nav/icon-products-white.svg"/>
                  </span>
                  {{ $t('mainNav.products') }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link class="dropdown-item"  to="/szg">{{ $t('mainNav.szg') }}</router-link>
                  <!-- <router-link class="dropdown-item"  to="#">{{ $t('mainNav.goodb') }}</router-link> -->
                  <!-- <router-link class="dropdown-item"  to="#">{{ $t('mainNav.bibao') }}</router-link> -->
                  <router-link class="dropdown-item"  to="/secret">{{ $t('mainNav.secret') }}</router-link>
                </div>
              </li>
              <li class="nav-item">
                <router-link class="nav-link"  to="/archive-news-and-announcements">
                  <span class="icon">
                    <img class="icon-blue" src="@/assets/img/main-nav/icon-about-us-blue.svg"/>
                    <img class="icon-white" src="@/assets/img/main-nav/icon-about-us-white.svg"/>
                  </span>
                  {{ $t('mainNav.news') }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link"  to="/careers">
                  <span class="icon">
                    <img class="icon-blue" src="@/assets/img/main-nav/icon-careers-blue.svg"/>
                    <img class="icon-white" src="@/assets/img/main-nav/icon-careers-white.svg"/>
                  </span>
                  {{ $t('mainNav.careers') }}
                </router-link>
              </li>
            </ul>

            
          </div>

          <div class="navbar-overlay"></div>

          <div class="functions d-none d-lg-block">
            <span class="dropdown dropdown-language">
              <img class="link-icon-language dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="../assets/img/language.svg" />

              <select id="locale-changer" v-model="$i18n.locale" class="d-none">
                <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
              </select>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">  
                <a v-for="(lang, i) in langs" :key="`Lang${i}`" class="dropdown-item" href="#" @click="changeLanguage(lang)">{{ lang }}</a>
              </div>
            </span>
            
            <img @click="openContact" class="link-icon-contact-us" src="../assets/img/contact-us.svg" />

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <img class="navbar-toggler-icon" src="@/assets/img/nav-menu.svg" />
            </button>
          </div>

        </nav>
    </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";
import 'bootstrap';
import $ from 'jquery';
import i18n from '../i18n';

export default {
  name: "MainNav",
  props: {
    src: String,
  },
  data () {
    return { 
      langs: ['English', '简体中文'] }
  },
  methods: {
    openContact(){
      EventBus.$emit('open-contact')
    },
    changeLanguage(value) {
      $("#locale-changer").val(value);
      $('#locale-changer').trigger('change');

      $('.language-selector .language').removeClass('active');

      $('.language-selector').find("[data-language='" + value + "']").addClass('active');

      i18n.locale = value;

      if ( value == 'English' ) {
        $('body').attr('data-language', 'en');
        $('body').removeClass('cn');
        $('body').addClass('en');
      } else {
        $('body').attr('data-language', 'cn');
        $('body').removeClass('en');
        $('body').addClass('cn');
      }
    },
  },
  created: function() {
    $(document).ready(() => {

        $('.navbar-nav .router-link-exact-active').closest('.nav-item').addClass('current-nav-item');

        $('.navbar-toggler').on('click', function() {
          $('.navbar-nav .router-link-exact-active').closest('.nav-item').addClass('current-nav-item');

          // if ( !$('.navbar-collapse').hasClass('show') ) {
          //   $('.navbar-overlay').css({ 'z-index': '1', 'opacity': '1' });
          //   console.log('hi');
          // } else {
          //   $('.navbar-overlay').css({ 'z-index': '-1', 'opacity': '0' });
          //   console.log('hello');
          // }

        });

        $('.navbar-overlay').on('click', function() {
          $('.navbar-toggler').click();
        });

        $('.close-button').on('click', function() {
          $('.navbar-toggler').click();
        });

        $('.language-selector').find("[data-language='English']").addClass('active');

        if ( !$('body').attr('data-language') ) {
          $('body').attr('data-language', 'en');
        }

    });
  }
};


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../assets/css/scss/main-nav.scss';
</style>
