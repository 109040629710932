<template>
  <div class="usp">
    <div class="outer-circle"></div>
    <p class="title">{{ $t(title) }}</p>
    <p class="description">{{ $t(description) }}</p>
  </div>
</template>

<script>
// import 'gsap';

export default {
  name: "usp",
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>

.usp {
  height: 230px;
  width: 230px;
  // border: 5px solid transparent;
  background-color: #3a4883;
  border-radius: 50%;
  box-shadow: 3px 3px 5px 5px #ccc, inset 0 0 10px #919fd0;
  color: white;
  text-align: center;

  vertical-align: middle;
  margin: 0 50px;
  position: relative;

  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 135px;
    width: 135px;
    margin: 0 25px;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    height: 15vw;
    width: 15vw;
  }

  @media (max-width: 767.98px) {
    margin: 0 auto;
  }

  .outer-circle {
    height: 100%;
    width: 100%;
    border: solid 5px transparent;
    border-radius: 50%;
    position: absolute;
    padding: 110px;

    @media (min-width: 768px) and (max-width: 1199.98px) {
      position: unset;
    }

    @media (min-width: 992px) {
      padding: 0;
    }

    &::after {
      $gutter-distance: 20px;
      content: "";
      position: absolute;
      top: -$gutter-distance;
      left: -$gutter-distance;
      bottom: -$gutter-distance;
      right: -$gutter-distance;
      border-radius: 50%;
      background: url(../../assets/img/circle.png) no-repeat center;
      background-size: contain;  
      box-shadow: 3px 3px 5px 1px #ccc; 

      @media (min-width: 768px) and (max-width: 991.98px) {
        top: -15px;
        left: -15px;
        bottom: -15px;
        right: -15px;
      }
    }
  }

  .title {
    font-size: 3rem;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0px 0px 50px rgba(#FAFAFA, 0.8);

    @media (min-width: 768px) and (max-width: 1199.98px) {
      font-size: 31px;
    }
  }
  .description {
    font-weight: 100;
    font-size: 1.2rem;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 768px) and (max-width: 1199.98px) {
      font-size: 1rem;
      width: 100%;
    }
  }
}
</style>
