const home_en = {
	contactUs: {
		title: 'Contact Us',
		formTitle: 'How Can We Help You?',
		formDescription: 'Talk To Us!',
	},
	introduction: {
		titleLine1: 'NOW YOU WILL FIND',
		titleLine2: 'A BEST PAYMENT',
		description: 'TO BE THE BEST PAYMENT',
		paragraph: 'In Aezypay, server hosting is implemented with best industrial practices that focus on usability, reliability, and expandability to fulfil high traffic demands and deliver smooth expansion based on high concurrency and transaction flow in order to ensure the best user experience from our products.',
		buttonText: 'GET TO KNOW MORE ABOUT OUR PRODUCT',
		iconDescription1: 'TRANSPARENT',
		iconDescription2: 'GLOBAL',
		iconDescription3: 'SECURITY',
		iconDescription4: 'PREMIUM',
	},
	highlights: {
		title1: '150+',
		title2: '$200B+',
		title3: '05',
		title4: '2009',
		msg1: 'Wallets',
		msg2: 'Transacted',
		msg3: 'Countries',
		msg4: 'Founded in'
	},
	aboutCompany: {
		title1: "Disaster Recovery",
		title2: "Encryption",
		title3: "Efficiency",
		title4: "Usability",
		description1: "Data centres are built and ready to be deployed at China, Hong Kong and South East Asia region to guarantee better remote disaster recovery practices.",
		description2: "Data transmission is encrypted and auto back-up to minimize cyber-attacks. We implement big data analysis platform to build up our security monitoring and defence system to ensure information security of user account and privacy.",
		description3: "Independent server modules are deployed for each Access, Business, Middleware and Data layers to guarantee high speed transmission. Each site is protected from DDoS attack to ensure better quality of user experience.",
		description4: "High usability framework delivers zero single point of failure (SPOF) for each layer of servers, balanced loading, horizontal scalability in order to sustain high volume of user access.",
	},
	details: {
		description1: "Secure and reliable digital asset financial services Years of experience in digital asset financial services.",
		description2: "Managed assets over $1 billion and served millions of users Professional distributed architecture and anti-DDOS attack system.",
		description3: "Global ecological layout International service. Into the Philippines, Taiwan, Hong Kong, Singapore, Thailand, Myanmar and other countries or regions. Planning based in Southeast Asia, expanding Europe and America."
	},
	features: {
		headerTitle: 'What are some of our advantages?',
		headerDescription: 'Get to know what our products can do for you',
		
		title1: 'OUR TEAM',
		description1: "Our R&D team is built and run by experts from different backgrounds of Finance, Information Technology, and Software Development. We provide professional consultation and services in planning, design, develop and management to our business partners.",
		title2: 'OUR PRODUCTS',
		description2: "With our experience and technology in asset trading and application development, we provide a variety of products: GoodB and Digital Valley apps. In addition, we work closely with 6x Exchange platform to provide more inclusive services to user.",
		title3: 'OUR EXPERIENCE',
		description3: "We guaranteed our services towards excellence with many years of experience in technology and finance industries.",

		title4: 'OUR SUPPORT',
		description4: "Our team has established a strict and efficient customer service policy and standard operation protocols based on customer-oriented and excellent service concept in order to deliver best quality of after sale support.",
		title5: 'GLOBAL CURRENCY EXCHANGE',
		description5: "Our platform supports multiple currency without the restrictions of cross-border transactions and allows you to convert fiat money as needed.",
		title6: 'FINANCIAL SECURITY',
		description6: "We ensure the security of funds for all transactions. No more risk control issues from third party platforms.",
	},
	chart: {
		headerTitle: 'Latest Trading Lorem Ipsum Dolor',
		headerDescription: 'Lorem ipsum dolor sit amet quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
	},
}

const home_cn = {
	contactUs: {
		title: '联系我们',
		formTitle: '我们能怎样帮助你？',
		formDescription: '让我们知道！',
	},
	introduction: {
		titleLine1: '找到你现在最好的',
		titleLine2: '付款方式',
		description: '是最好的付款方式。',
		paragraph: 'Aezypay服务器托管服务采用专用部署方案，在安全、可用、可靠与可拓展性方面做到最优，可以完全应对该领域高可用,高稳定,高并发,低延迟的访问需求，并能够根据资金流量发展实现平滑扩容，实现完美服务器托管，保障Aezypay系统安全稳定运行。',
		buttonText: '进一步了解我们的产品',
		iconDescription1: '澄清',
		iconDescription2: '全球',
		iconDescription3: '保障',
		iconDescription4: '优质',
	},
	highlights: {
		title1: '150+',
		title2: '$200B+',
		title3: '05',
		title4: '2009',
		msg1: '钱包',
		msg2: '交易',
		msg3: '国家',
		msg4: '成立于'
	},
	aboutCompany: {
		title1: "全球部署 多中心容灾",
		title2: "加密传输 高等级的安全防护",
		title3: "独立部署 专享高效",
		title4: "高可用 负载均衡架构",
		description1: "在中国大陆，香港，东南亚等地区拥有服务器机房，实现异地灾备业务容灾架构。",
		description2: "数据加密传输，自动备份，将黑客攻击可能降至最低，大数据安全分析平台帮助我们建立安全监控和防御体系，保障用户信息安全。",
		description3: "接入层，业务层，中间键，数据层独立服务器模块部署，独享带宽，光缆传播，全站拥有可防护40GB流量的DDOS攻击，给用户带来优质体验。",
		description4: "高可用架构实现各层服务器无单点故障，负载均衡解决高并发并能提高水平横向扩展，应对更多用户访问体验。",
	},
	details: {
		description1: "安全可靠的数字化资产金融服务年数字资产金融服务的经验。",
		description2: "管理资产超过10亿美元，为数百万用户提供专业的分布式架和反DDOS服务攻击系统。",
		description3: "全球生态布局国际服务。进入菲律宾，台湾，香港，新加坡，泰国，缅甸等国家或地区规划总部设在东南亚，扩大欧美。"
	},
	features: {
		headerTitle: '我们的一些优势是什么？',
		headerDescription: '了解我们的产品可以为您做些什么',
		
		title1: '专业，优秀的技术团队',
		description1: "我们的研发团队由行业经验丰富的金融，IT，软件研发专家组成，有着丰富的平台设计，开发，管理，集成，咨询经验，可以为合作伙伴提供可靠专业的服务体验。",
		title2: '全球货币转换',
		description2: "跨境交易不受限制，您可以根据需要转换法定货币，并支持全球多种货币。",
		title3: '丰富的建设经验',
		description3: "Aezypay累积了丰富的技术与金融服务经验，极大的提高了服务效率，保障客户服务质量。",

		title4: '健全的售后服务',
		description4: "Aezypay拥有专业的售后服务团队，建立了一套高效的，严格的客户服务标准和运维规范流程，以客户至上，精诚服务为理念，为客户提供幼稚，迅速，高校的售后服务。",
		title5: '完善的产品体系',
		description5: "随着公司不断的发展，Aezypay逐渐形成了集技术研发，资产交易，应用构建为一体的技术服务体系，拥有goodb，数字谷app产品，并与6x交易网站secret社交等产品有深度合作，为用户提供更加全面的服务。",
		title6: '财务安全',
		description6: "所有交易中的货币兑换确保了资金的安全性，并解决了过去第三方平台风险控制的问题。",
	},
	chart: {
		headerTitle: '最新的交易信息',
		headerDescription: 'Lorem ipsum dolor sit amet quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
	},
}

export { home_en, home_cn } 