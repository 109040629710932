const szg_en = {
  download: {
    headerTitle1: "Store, buy & sell: Digital Valley",
    headerTitle2: "",
    description: "Digital Valley is a safe wallet that allows you to manage all the different types of coins. Watch your purchasing power go up now!",
    ios: "Download on IOS",
    andriod: "Download on Android"
  },
  szgCard: {
    title1: "Consumers First",
    title2: "Robust Trading",
    title3: "Security First",
    description1: "Our consumers should own their financial future. Hence we empower them to be their own investor and bank. Our users will come first.",
    description2: "Our custom trading platform was designed to be scalable and to ensure that orders are executed in real-time.",
    description3: "Our users' security and privacy are critical in every decision we make, and we incorporate multiple layers of protection to keep your funds secure so that you can trade and invest in peace.",
  },
  szgDemo: {
    title1:"Simplicity",
    subtitle1:"Investing has never been easier.",
    description1:"Digital Valley offers a simple interface on our mobile application to manage the online investment transactions, without having to provide credit card information for each transaction. We offer a great range of services that eases online investment & money management.",
    title2:"Speed",
    subtitle2:"Immediate transactions.",
    description2:"Transaction speed is one of Digital Valley's top priorities.Borrow, withdraw & trade almost in real time with us now.",
    title3:"Security",
    subtitle3:"Secured transactions.",
    description3:"Digital Valley is supported by a high security protocol that guarantees absolute privacy and safety to your online trading transactions.",
  },
}

const szg_cn = {
  download: {
    headerTitle1: "储存，买 & 卖：",
    headerTitle2: "数字谷",
    description: "数字谷是一款安全的钱包，可让您管理所有不同类型的硬币。现在就注意你的购买力！",
    ios: "iOS 下载",
    andriod: "Android 下载"
  },
  szgCard: {
    title1: "消费者会是第一",
    title2: "强劲的交易",
    title3: "安全第一",
    description1: "我们的消费者应拥有自己的财务未来因此，我们授权他们成为自己的投资者和银行。我们的用户将是第一位的。",
    description2: "我们的定制交易平台旨在实现可扩展性，并确保订单实时执行。",
    description3: "我们用户的安全和隐私在我们做出的每项决策中都至关重要，并且我们采用多层保护措施来确保您的资金安全，以便您可以进行交易和投资。",
  },
  szgDemo: {
    title1:"简约设计",
    subtitle1:"投资从未如此简单",
    description1:"数字谷在我们的移动应用程序上提供了一个简单的界面来管理在线投资交易，而无需为每笔交易提供信用卡信息。我们提供广泛的服务，简化在线投资和资金管理。",
    title2:"速度",
    subtitle2:"即时交易",
    description2:"交易速度是数字谷的首要任务之一。 现在几乎与我们实时借款，取款和交易。",
    title3:"安全",
    subtitle3:"担保交易",
    description3:"数字谷拥有高安全性协议，可确保您的在线交易绝对隐私和安全。",
  },
}

export { szg_en,  szg_cn } 