<template>
  <section class="row">
    <div class="col-12 section-chart">
        <h2 class="title">{{ $t('chart.headerTitle') }}</h2>
        <p class="description">{{ $t('chart.headerDescription') }}</p>
      <div>
        <img class="trading-background img-fluid" src="@/assets/img/latest-trading.png" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionChart",
  components: {
    
  },
  data: function() {
    return {
    
    };
  },  
  methods: {

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/css/scss/home/section-chart.scss";
</style>
