const news_and_announcements_en = {
	newsPageHeader: {
		headerTitle1: 'News &',
		headerTitle2: 'Announcements',
		paragraph: 'Read our latest news and announcements to lorem, ipsum dolor sit amet consectetur adipisicing elit. Soluta magni aspernatur molestias cupiditate ipsam magni aspernatur.',
    },
    archiveNews: {
        navAll: 'All',
        navNews: 'News',
        navAnnouncements: 'Announcements',
        navPrev: 'Previous',
        navNext: 'Next',
        readMore: 'Read more',
    }
}

const news_and_announcements_cn = {
	newsPageHeader: {
		headerTitle1: '新闻和',
		headerTitle2: '公告',
		paragraph: 'Read our latest news and announcements to lorem, ipsum dolor sit amet consectetur adipisicing elit. Soluta magni aspernatur molestias cupiditate ipsam magni aspernatur.',
    },
    archiveNews: {
        navAll: '所有文章',
        navNews: '新闻',
        navAnnouncements: '公告',
        navPrev: '上一页',
        navNext: '下一页',
        readMore: '阅读更多',
    }
}

export { news_and_announcements_en, news_and_announcements_cn } 