const secret_en = {
    secretHeader: {
        headerTitle1: "Explore the world with Secret",
        headerTitle2: "",
        description: "Real-time instant messaging and a variety of features for making new friends, Secret is a one-stop super app for lifestyle, commerce and entertainment. Download Secret. one today for a new social experience!",
    },
    secretAbout: {
        title: "ABOUT SECRET",
        description: "Secret is encrypted to provide security and privacy protection for users. Secret believes in a safe and secure social network. Secret provides a one-stop platform which encompasses Instant Messaging, community groups and sharing, payment, e-commerce, gaming, entertainment and many more to create a smarter and more efficient digital lifestyle.",
    },
    secretFeatures: {
        title1: "USER FRIENDLY",
        description1: 'secret has a specially designed user interface based on user habits.secret provides not only communities but also all kinds of features like gifting, photo/video/file sharing, gaming, and more to meet your social needs.',
        title2: "FREE ACCESS",
        description2: 'Send instant messages, make video chats, group chats, share updates and contact friends anytime &amp; anywhere on secret for free.',
        title3: "INTEGRATED WALLET",
        description3: 'secret Pay provides an all in one points system for use on secret. SECRET provides users with a secure and easy to use points system for all e-commerce stores, games and applications that are integrated with secret. secret is a one-stop platform to meet users’ needs for all aspects of online consumption. secret provides access for applications into the global digital economy.',
    },
    secretMinorFeatures: {
        title1: "USER PRIVACY PROTECTION",
        description11: 'secret is designed with privacy in mind. secret provides encrypted chats that transmits end-to-end without leaving any traces on servers. No third party can decrypt conversations.',
        description12: 'In order to better protect the privacy of users, one can choose to erase messages after reading by setting a destruction timer.',
        title2: "COMMUNITY SUPPORT",
        description2: 'secret supports the rapid establishment of communities by providing multi-language text & voice messages, sharing photo/files, gifting, as well as many other useful features.',
        title3: "OTHER FEATURES",
        description31: 'Use @ to tag your friend',
        description32: 'Reply any specific message in a group chat',
        description33: 'Quickly delete or forward multiple messages',
        description34: 'Share contact information as namecards',
        description35: 'Express yourself with secret’s emoticons and stickers',
        description36: 'Share your best moments in the timeline',
    },
}

const secret_cn = {
    secretHeader: {
        headerTitle1: "用secret探索世界",
        headerTitle2: "",
        description: "超强大的即时通讯，各种姿势聊好友，生活娱乐一步满足！即刻下载secret，开启全新社交之旅！",
    },
    secretAbout: {
        title: "关于 SECRET",
        description: "secret是一款跨平台的即时通讯应用，功能强大而简单易用，经过加密可为用户带来隐私和安全保护。secret坚信，安全可靠是社交网络的共同未来。 同时secret提供一站式多功能服务平台，包括IM、社群、分享、支付、电子商务、游戏娱乐等，以创建更智能更高效的数字生活方式。",
    },
    secretFeatures: {
        title1: "简单好用",
        description1: 'secret设计参考了大量用户操作习惯，界面简洁美观，交互方式友好，零成本的学习使用，新用户也能快速上手。除了基本的聊天通讯功能，发红包、传照片、传文件、玩游戏、朋友圈，各种功能全面极致，满足您社交上的各种需求。',
        title2: "完全免费",
        description2: '通过互联网连接，即可使用secret免费发送即时消息、进行视频聊天、群聊和分享动态，随时随地联系您的好友。',
        title3: "积分钱包",
        description3: 'secret通过集成电子商务商店、游戏和应用中心等来扩展其应用场景，并基于多功能建立起一个一体化的积分钱包系统，可安全、快速、方便地实现积分存储、管理、支付、转账、充值、提现等操作，满足用户对在线消费各方面的需求，为各种应用提供进入全球数字经济的途径。',
    },
    secretMinorFeatures: {
        title1: "用户隐私保护",
        description11: 'secret设计充分考虑了用户的隐私安全。我们提供端到端传输的私密对话，信息传输不会在服务器上留下任何痕迹，只有私密对话的双方才能读取到这些聊天消息，任何第三方都无法破解，聊天和通话具有可靠的隐私保护。',
        description12: '您还可以选择使用secret阅后即焚功能，为私密消息设定生命周期，阅后到期即自动销毁，更好地保护私密聊天安全。',
        title2: "群组功能",
        description2: 'secret支持快速建立数千人的大规模群，您可以与全球各地的用户，以文字、语音、视频、图片、群红包等方式群聊，分享各种激动人心的内容，进行多元丰富的互动。',
        title3: "贴心功能",
        description31: '群组中@Ta提醒关注消息',
        description32: '快速删除、转发多条信息',
        description33: 'secret独家Q萌表情',
        description34: '回复群聊中任何特定消息',
        description35: '快速发送联系人名片',
        description36: '朋友圈分享美好瞬间',
    },
}

export { secret_en,  secret_cn } 