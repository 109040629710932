<template>
    <section class="row section section-white section-news">

        <!-- Image -->
        <div class="col-12 col-md-5 offset-lg-1 col-lg-3 col-xl-3">
            <img class="image image-horizontal-scroll" src="@/assets/img/latest-news-with-heading.svg">
        </div>

        <!-- Carousel: News -->
        <div class="col-12 col-md-7 col-lg-6 col-xl-7">

            <!-- Carousel -->
            <div class="carousel-news">
                <div class="overlay-carousel-news"></div>
                <div class="horizontal-scroll">
                    <h2 class="no-news">No news and announcements available</h2>
                    <!-- <NewsCard v-for="(news, index) in newsAndAnnouncements" :key="news.id" :index="index" :title="news.enTitle" :paragraph="news.enContent" :img="`bg-post-1.png`"/> -->
                </div>
            </div>

            <!-- Prev and Next Toggles -->
            <!-- <div class="page-toggle">
                <img class="prev" src="@/assets/img/pagination-3.svg" @click="prevSlide()" />
                <img class="next" src="@/assets/img/pagination-2.svg" @click="nextSlide()"/>
            </div> -->

        </div>
    </section>
</template>

<script>
import NewsCard from "@/components/partials/News-Card.vue";
import axios from "axios";

const slides = document.querySelectorAll('.news-card');
const prev = document.querySelector('.prev');
const next = document.querySelector('.next');

export default {
    name: "SectionNews",
    components: {
        NewsCard,
    },
    data: function() {
        return {
            newsAndAnnouncements: null,
        };
    },
    created: function() {
        //this.getNewsAndAnnouncements();
    },
    methods: {
        async getNewsAndAnnouncements() {
            await axios.get("http://testdeployd.aezypay.com/announcements").then(res => (this.newsAndAnnouncements = res.data));

            this.addCurrentToFirstNews();
        },
        addCurrentToFirstNews() {
            document.querySelector('.news-card').classList.add('current');
        },
        prevSlide: function() {
            const slides = document.querySelectorAll('.news-card');
            const current = document.querySelector('.current');

            current.classList.remove('current');

            if (current.previousElementSibling) {
                current.previousElementSibling.classList.add('current');
            } else {
                slides[slides.length - 1].classList.add('current');
            }

            this.slideTo();
        },
        nextSlide: function() {
            const slides = document.querySelectorAll('.news-card');
            const current = document.querySelector('.current');

            current.classList.remove('current');

            if (current.nextElementSibling) {
                current.nextElementSibling.classList.add('current');
            } else {
                slides[0].classList.add('current');
            }

            this.slideTo();
        },
        slideTo: function() {
            const current = document.querySelector('.current');
            const currentIndex = current.getAttribute('index');
            const horizontalScroll = document.querySelector('.horizontal-scroll');
            const scrollAmount = document.querySelector('.news-card');

            let horizontalScrollWidth = scrollAmount.clientWidth;

            // const imageWidth = document.querySelector('.image-horizontal-scroll').innerWidth;

            horizontalScroll.scrollLeft = (currentIndex) * horizontalScrollWidth; // Mobile
            // horizontalScroll.scrollLeft = (currentIndex - 1) * horizontalScrollWidth; // Tablet
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/css/scss/home/section-news.scss";
</style>
