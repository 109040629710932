<template>
  <div class="footer">
    <p class="title">ASIAEZYPAY PTE LTD, &copy; {{ year }} </p>

    <a href="https://www.facebook.com/asiaezypay" target="_blank"><img class="fb-footer" src="@/assets/img/fb-footer.svg"></a>
    <a href="https://www.linkedin.com/company/asia-ezypay" target="_blank"><img class="linkedin-footer" src="@/assets/img/linkedin-footer.svg"></a>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    year(){
      return new Date().getFullYear()
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/css/scss/footer.scss';

.footer {
  position: relative;

  .fb-footer,
  .linkedin-footer {
    position: absolute; 
    right: 60px;
    top: 15px;
    width: 30px;
  }

  .fb-footer {
    right: 115px;
  }
}
</style>

