<template>
  <!-- Section: About Company -->
  <section class="section-about-company">
    <div class="row">
      <!-- Section Header -->
      <!-- <div class="col-12 section-header">
        <h2 class="title">{{ title }}</h2>
        <p class="description">{{ description }}</p>
      </div>-->

      <!-- Content -->
      <div class="col-12 offset-lg-1 col-lg-10 offset-xl-6 col-xl-6 content">
        <!-- For each TextBlock -->
        <!-- Attempt: Add opacity classes base on iteration using computed value or method -->
        <!-- <div class="row opacity-40">
              <div class="col-12">
                  <div class="animated invisible">
                      <div class="row">
        <div class="col-auto">-->
        <!-- Use iteration's index -->
        <!-- <p class="index">1</p> -->
        <!-- </div>
                        <div class="col">
                            <TextBlock class="text-block" title="aboutCompany.title1" description="aboutCompany.description1" icon=""/>
                        </div>
                      </div>
                  </div>
              </div>
        </div>-->
        <div class="row opacity-40">
          <div class="col-12">
            <div class="animated invisible">
              <div class="row">
                <div class="col-3 col-md-2 col-lg-auto space-index">
                  <!-- Use iteration's index -->
                  <p class="index">1</p>
                </div>
                <div class="col col-md-9 col-lg-10 col-xl-9">
                  <TextBlock
                    class="text-block"
                    title="aboutCompany.title1"
                    description="aboutCompany.description1"
                    icon="globe.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row opacity-60">
          <div class="col-12">
            <div class="animated invisible">
              <div class="row">
                <div class="col-3 col-md-2 col-lg-auto space-index">
                  <!-- Use iteration's index -->
                  <p class="index">2</p>
                </div>
                <div class="col col-md-9">
                  <TextBlock
                    class="text-block"
                    title="aboutCompany.title2"
                    description="aboutCompany.description2"
                    icon="security.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row opacity-80">
          <div class="col-12">
            <div class="animated invisible">
              <div class="row">
                <div class="col-3 col-md-2 col-lg-auto space-index">
                  <!-- Use iteration's index -->
                  <p class="index">3</p>
                </div>
                <div class="col col-md-9">
                  <TextBlock
                    class="text-block"
                    title="aboutCompany.title3"
                    description="aboutCompany.description3"
                    icon="premium-currency.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row opacity-100">
          <div class="col-12">
            <div class="animated invisible">
              <div class="row">
                <div class="col-3 col-md-2 col-lg-auto space-index">
                  <!-- Use iteration's index -->
                  <p class="index">4</p>
                </div>
                <div class="col col-md-9">
                  <TextBlock
                    class="text-block"
                    title="aboutCompany.title4"
                    description="aboutCompany.description4"
                    icon="transparent-disclosure.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import $ from "jquery";
import TextBlock from "@/components/partials/TextBlock.vue";
import SideSectionheading from "@/components/SideSectionHeading.vue";

export default {
  name: "SectionAboutCompany",
  components: {
    TextBlock,
    SideSectionheading
  },
  props: {
    title: String,
    description: String
  },
  created: function() {
    $(document).ready(() => {
      var vw = window.innerWidth;

      if (vw < 768) {
        // if viewport is mobile
      } else {
        // if viewport is tablet and larger
        const chainFunctions = new ChainFunctions();

        $.fn.isInViewport = function() {
          var elementTop = $(this).offset().top;
          var elementBottom = elementTop + $(this).outerHeight();

          var viewportTop = $(window).scrollTop();
          var viewportBottom = viewportTop + $(window).height();

          return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        $(window).on("scroll", function() {
          if ($(".section-about-company").isInViewport()) {
            chainFunctions.animateTextBlocks();
          } else {
            chainFunctions.hideTextBlocks();
          }
        });

        chainFunctions.animateTextBlocks();
      }
    });

    class ChainFunctions {
      animateTextBlocks = () => {
        $(".section-about-company .animated").each((i, element) => {
          setTimeout(() => {
            $(element)
              .removeClass("invisible")
              .addClass("fadeInUp");
          }, 300 * i);
        });

        return this;
      };

      hideTextBlocks = () => {
        $(".section-about-company .animated")
          .addClass("invisible")
          .removeClass("fadeInUp");

        return this;
      };
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../assets/css/scss/home/section-about-company.scss";
</style>
