import { render, staticRenderFns } from "./HighlightCircles.vue?vue&type=template&id=64bf27df&scoped=true&"
import script from "./HighlightCircles.vue?vue&type=script&lang=js&"
export * from "./HighlightCircles.vue?vue&type=script&lang=js&"
import style0 from "./HighlightCircles.vue?vue&type=style&index=0&id=64bf27df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64bf27df",
  null
  
)

export default component.exports