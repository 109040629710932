const careers_en = {
    careersPageHeader: {
        title: 'CAREERS',
        paragraph1: "At Aezypay, you will work with talented engineers, designers and thought leaders to shape the future of online investments.",
        paragraph2: "We hail from over 5 different countries and apply an incredible breadth of experience to our work.",
    },
    perksAndBenefits: {
        headerTitle: 'THE PERKS & BENEFITS',
        headerDescription1: 'Are you ready to make an impact',
        headerDescription2: 'to the crypto world? Join us now!',

        title1: 'Conducive Environment',
        paragraph1: 'Enjoy challenging yourself at work, and a satisfying job without any hierarchy.',
        
        title2: 'Shared Success',
        paragraph2: 'Equal ownership and opportunities awaiting you.',

        title3: 'Snacks',
        paragraph3: 'Snacks are on the house so that it feels like home.',

        title4: 'Learning Culture',
        paragraph4: 'Keep your brain rejuvenated in the company by learning from our expert colleagues.',
    },
    openPositions: {
        title: 'OPEN POSITIONS',
        paragraph: 'Find a position in the company that suits you best!',
        searchBtn: 'Search',
        applyNow: 'Apply Now',
    },
}

const careers_cn = {
    careersPageHeader: {
        title: '职业招聘',
        paragraph1: "在 Aezypay，您将与才华横溢的工程师，设计师和思想领袖合作，共同塑造在线投资的未来。",
        paragraph2: "我们来自5个不同的国家，并在我们的工作中应用了无可比拟的经验。",
    },
    perksAndBenefits: {
        headerTitle: '优点和好处',
        headerDescription1: '你准备好对加密世界产生影响了',
        headerDescription2: '吗？现在就加入我们！',

        title1: '有利环境',
        paragraph1: '在工作中享受挑战自我，在没有任何等级的情况下享受令人满意的工。',
        
        title2: '共享成功',
        paragraph2: '平等的所有权和等待你的机会。',

        title3: '小吃',
        paragraph3: '公司里有小吃，感觉像家一样，拥有一个舒服与开心的环境。',

        title4: '学习文化',
        paragraph4: '通过向我们的专家同事学习，让您的大脑恢复活力也让您增加知识。',
    },
    openPositions: {
        title: '空缺职位',
        paragraph: '在公司找到最适合您的职位！',
        searchBtn: '搜索',
        applyNow: '申请',
    },
}

export { careers_en, careers_cn } 