<template>
    <div class="side-section-heading">
        <label class="title">{{ title }}</label>
    </div>
</template>

<script>
export default {
  name: 'SideSectionHeading',
  props: {
    title: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../assets/css/scss/side-section-heading.scss';
</style>
