<template>
  <section class="row section-details">
    <div class="fadeIn delay-2s slow">
      <div class="overlaying-contents">
        <Bident bidentId="bident-1" direction="bident-left"/>
        <Bident bidentId="bident-2" direction="bident-left"/>
        <Bident bidentId="bident-3" direction="bident-left"/>
        <PulsingCircle class="pulse-1"/>
        <PulsingCircle class="pulse-2"/>
        <PulsingCircle class="pulse-3"/>
      </div>
    </div>

    <div class="offset-lg-1 col-lg-3 contents">
      <div class="fadeInRight slow">
        <div class="left text-left pr30">
          <text-block :description="$t('details.description1')" class="description-1" />
          <text-block :description="$t('details.description2')" class="description-2" />
          <text-block :description="$t('details.description3')" class="description-3" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery';
import TextBlock from "@/components/partials/TextBlock.vue";
import Bident from "@/components/partials/Bident.vue";
import PulsingCircle from "@/components/partials/PulsingCircle.vue";

export default {
  name: "SectionDetails",
  components: {
    TextBlock,
    Bident,
    PulsingCircle
  },
  data: function() {
    return {
      show: false
    };
  },  
  mounted: function() {
    this.handleScroll();
  },
  methods: {
    isInViewport() {
      let elementTop = this.$el.offsetTop;
      let elementBottom = elementTop + this.$el.offsetHeight;

      let viewportTop = window.scrollY;
      let viewportBottom = viewportTop + window.innerHeight;

      return elementBottom > viewportTop && elementTop < viewportBottom;
    },
    handleScroll() {
      var vw = window.innerWidth;
      
      if (vw < 768) { // if viewport is mobile
      
      } else { // if viewport is tablet and larger
        
        let elements = document.getElementsByClassName('section-details')[0].getElementsByClassName('slow');

        let i = 0;

        for (i = 0; i < elements.length; i++) {
          elements[i].classList.add('animated');
        }

        if (this.isInViewport()) {
          this.show = true;
        } else {
          this.show = false;
        }
      }
      
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../assets/css/scss/home/section-details.scss";
</style>
